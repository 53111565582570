<template>
  <v-row>
    <v-col>
      <el-time-picker
        v-model="startTimeComputed"
        :placeholder="$t('access-modes.startDay')"
        class="w-100"
        size="large"
      />
    </v-col>
    <v-col>
      <el-time-picker
        v-model="expireTimeComputed"
        :placeholder="$t('access-modes.endDay')"
        class="w-100"
        size="large"
      />
    </v-col>
    <v-col cols="auto">
      <v-tooltip text="Tooltip">
        <template v-slot:activator="{ props }">
          <v-btn
            v-bind="props"
            :ripple="false"
            color="red"
            variant="text"
            size="40"
            density="comfortable"
            icon="mdi-clock-remove-outline"
            @click="removeTimeWindow"
          />
        </template>
        {{ $t("access-modes.removeTimeWindow") }}
      </v-tooltip>
    </v-col>
  </v-row>
</template>
<script>
export default {
  emits: ["update:modelValue", "remove"],

  props: {
    modelValue: {
      type: Object,
      default: () => {},
    },

    defaultValue: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      defaultState: {
        startTime: null,
        expireTime: null,
      },
    };
  },

  methods: {
    setDefaultValue() {
      const curData = {
        startTime:
          this.modelValue?.startTime ??
          this.defaultValue?.startTime ??
          this.defaultState?.startTime,

        expireTime:
          this.modelValue?.expireTime ??
          this.defaultValue?.expireTime ??
          this.defaultState?.expireTime,
      };

      this.updateModelValue(curData);
    },

    removeTimeWindow() {
      this.$emit("remove");
    },

    updateModelValue(data) {
      this.$emit("update:modelValue", data);
    },
  },

  computed: {
    startTimeComputed: {
      get() {
        return this.modelValue?.startTime;
      },
      set(newValue) {
        let expireTime = this.modelValue.expireTime;

        if (!!expireTime && this.$moment(newValue).isAfter(expireTime)) {
          expireTime = newValue;
        }

        const curData = {
          startTime: newValue,
          expireTime: expireTime,
        };

        this.updateModelValue(curData);
      },
    },
    expireTimeComputed: {
      get() {
        return this.modelValue?.expireTime;
      },
      set(value) {
        let newValue = value;

        if (
          this.modelValue.startTime &&
          this.$moment(newValue).isBefore(this.modelValue.startTime)
        ) {
          newValue = this.modelValue.startTime;
        }

        const curData = {
          startTime: this.modelValue.startTime,
          expireTime: newValue,
        };

        this.updateModelValue(curData);
      },
    },
  },

  created() {
    this.setDefaultValue();
  },
};
</script>
