<template>
  <v-container fluid class="fill-height blue-grey-lighten-2">
    <v-row>
      <v-col class="d-flex justify-center">
        <v-card width="400">
          <v-card-title>{{ $t("login.ToLogin") }}</v-card-title>
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    variant="underlined"
                    color="primary"
                    v-model.trim="username"
                    :label="`${$t('login.Login')} *`"
                    :rules="requiredRules"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    variant="underlined"
                    color="primary"
                    v-model.trim="password"
                    type="password"
                    :label="`${$t('login.Password')} *`"
                    :rules="requiredRules"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" block @click="login" variant="flat">
              <v-icon left>mdi-login</v-icon>
              {{ $t("login.Entry") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";

import userRedirect from "../mixins/userRedirect";

export default {
  mixins: [userRedirect],
  data() {
    return {
      valid: true,
      username: null,
      password: null,

      requiredRules: [(v) => !!v || this.$t("message.NameIsRequired")],
    };
  },
  methods: {
    async login() {
      await this.$refs.form.validate().then((result) => {
        if (!result.valid) return;

        this.$auth
          .login({
            data: {
              username: this.username,
              password: this.password,
            },
            rememberMe: true,
            method: "POST",
            fetchUser: true,
          })
          .then(() => {
            this.tryRedirect(this.curPageState);
          })
          .catch(() => {
            this.$toast.error(this.$t("login.Error"));
          });
      });
    },
  },

  computed: {
    ...mapState({
      curPageState: (state) => state.stateSystem.curPage,
    }),
  },
};
</script>
